export default {
	"语言": "Bahasa",
	"首页": "Beranda",
	"电影": "Film",
	"投票": "Voting",
	"我的": "Saya",
	"请求失败": "Permintaan gagal",
	"选妃": "Pilih Istri",
	"高端": "High-end",
	"认证": "Verifikasi",
	"视频认证": "Verifikasi Video",
	"收费区间": "Rentang Biaya",
	"身高": "Tinggi",
	"胸围": "Ukuran Dada",
	"服务城市：全国各地 航空运输：是": "Kota layanan: Seluruh negeri, Pengiriman udara: Ya",
	"我要约她": "Saya ingin mengajaknya",
	"住所": "Alamat",
	"个人简介": "Profil Pribadi",
	"请联系顾问或接待员": "Silakan hubungi konsultan atau resepsionis",
	// "投票": "Voting",
	"全部": "Semua",
	"刷新成功": "Pembaruan berhasil",
	"尽享高端区": "Nikmati area premium",
	"享受极致奢华体验": "Nikmati pengalaman mewah yang tiada tara",
	"空降": "Terjun",
	"查看更多": "Lihat lebih banyak",
	"热门推荐": "Rekomendasi Populer",
	"获取中": "Mengambil",
	"登录": "Masuk",
	"请输入用户名": "Silakan masukkan nama pengguna",
	"请输入密码": "Silakan masukkan kata sandi",
	"忘记密码": "Lupa kata sandi",
	"没有账户？马上注册": "Belum punya akun? Daftar sekarang",
	// "请输入用户名": "Silakan masukkan nama pengguna",
	// "请输入密码": "Silakan masukkan kata sandi",
	"注册": "Daftar",
	"请输入用户名(6-12个英文字母或数字)": "Silakan masukkan nama pengguna (6-12 huruf atau angka)",
	"请输入登录密码(6-12个英文字母或数字)": "Silakan masukkan kata sandi login (6-12 huruf atau angka)",
	"请输入邀请码": "Silakan masukkan kode undangan",
	"我已经知晓并同意开户协议各项条约": "Saya telah mengetahui dan setuju dengan semua syarat perjanjian pembukaan akun",
	// "请输入邀请码": "Silakan masukkan kode undangan",
	"请勾选下方开户协议": "Silakan centang perjanjian pembukaan akun di bawah",
	"投票结果": "Hasil Voting",
	"提交": "Kirim",
	"当前选号": "Pilihan saat ini",
	"每注金额": "Jumlah per taruhan",
	"请输入金额": "Silakan masukkan jumlah",
	"元": "Rp",
	"总共": "Total",
	"注": "Taruhan",
	"合计": "Jumlah",
	"任务单": "Daftar Tugas",
	"清空订单": "Kosongkan pesanan",
	"确认提交": "Konfirmasi pengiriman",
	"期号": "Nomor isu",
	"中奖号码": "Nomor pemenang",
	"鲜花": "Bunga",
	"气球": "Balon",
	"棒棒糖": "Lollipop",
	"烟花": "Kembang api",
	"未选择": "Belum dipilih",
	"金额错误": "Jumlah salah",
	"请选号": "Silakan pilih nomor",
	"余额不足，请联系客服充值": "Saldo tidak cukup, silakan hubungi layanan pelanggan untuk mengisi ulang",
	"未选中": "Belum dipilih",
	"请联系管理员领取该任务": "Silakan hubungi administrator untuk mengambil tugas ini",
	"开奖成功，期号：": "Pengundian berhasil, nomor isu:",
	"请输入您的收款卡信息": "Silakan masukkan informasi kartu pembayaran Anda",
	"银行卡号": "Nomor Rekening Bank",
	"请输入真实银行卡号": "Nomor Rekening Bank",
	"银行名称": "Nama Bank",
	"请选择银行": "Nama Bank",
	"尊敬的用户,为了保障您的资金安全,请您绑定您的真实姓名和设置取款密码,如果姓名与开户名不一致,将无法取款": "Pengguna yang terhormat, untuk menjaga keamanan dana Anda, harap sambungkan nama asli Anda dan atur kata sandi penarikan. Jika nama tidak cocok dengan nama akun, penarikan tidak akan mungkin.",
	"确认绑卡": "Konfirmasi pengikatan kartu",
	"请输入银行卡号": "Silakan masukkan nomor kartu bank",
	// "请选择银行": "Silakan pilih bank",
	"任务记录": "Rekam tugas",
	"数据为空": "Data kosong",
	"下注金额": "Jumlah taruhan",
	"下单时间": "Waktu pemesanan",
	"结算时间": "Waktu penyelesaian",
	"充值": "Isi ulang",
	"提现": "Penarikan",
	"我的钱包": "Dompet saya",
	"余额(元)": "Saldo (Rp)",
	"个人中心": "Pusat Pribadi",
	"系统公告": "Pengumuman Sistem",
	"在线客服": "Layanan Pelanggan Online",
	"退出登录": "Keluar",
	"请完成任务单后进入": "Silakan selesaikan daftar tugas sebelum masuk",
	"请联系客服充值": "Silakan hubungi layanan pelanggan untuk mengisi ulang",
	"请设置收款卡": "Silakan atur kartu pembayaran",
	"功能已禁用": "Fungsi telah dinonaktifkan",
	"账号下线": "Akun offline",
	"登录/注册": "Masuk/Daftar",
	"登录可享受更多服务": "Masuk untuk menikmati lebih banyak layanan",
	"基本信息": "Informasi Dasar",
	"头像": "Gambar",
	"选择头像": "Pilih Gambar",
	"确定": "Konfirmasi",
	"真实姓名": "Nama Pemilik Rekening",
	"未设置": "Belum diatur",
	"性别": "Jenis Kelamin",
	"男": "Pria",
	"女": "Wanita",
	"未知": "Tidak diketahui",
	"绑定信息": "Informasi Pengikatan",
	"已绑定": "Telah Terikat",
	"无": "Tidak ada",
	"收款卡信息": "Informasi Kartu Pembayaran",
	"添加收款卡": "Tambahkan Kartu Pembayaran",
	"提示:请绑定大型商业银行,如需修改,请您联系在线客服": "Tip: Harap sambungkan ke bank komersial besar. Jika perlu mengubah, silakan hubungi layanan pelanggan online.",
	"请设置姓名后再绑定银行卡": "Silakan atur nama Anda sebelum mengikat kartu bank.",
	"修改登录密码": "Ubah Kata Sandi Masuk",
	"旧密码": "Kata Sandi Lama",
	"请输入您的旧密码": "Silakan masukkan kata sandi lama Anda",
	"新密码": "Kata Sandi Baru",
	"请输入您的新密码": "Silakan masukkan kata sandi baru Anda",
	"请再次输入您的新密码": "Silakan masukkan kembali kata sandi baru Anda",
	"保存": "Simpan",
	"修改真实姓名": "Ubah Nama Asli",
	"姓名": "Nama",
	"请输入真实姓名": "Nama",
	"为了您账户安全,真实姓名需要与绑定银行卡姓名一致": "Untuk keamanan akun Anda, nama asli harus sesuai dengan nama pada kartu bank yang terikat.",
	"请勿重复设置": "Harap tidak mengatur ulang.",
	"性别修改": "Ubah Jenis Kelamin",
	"提现中心": "Pusat Penarikan",
	"提现记录": "Rekaman Penarikan",
	"提现金额 (元)": "Jumlah Penarikan (Rp)",
	// "全部": "Semua",
	"1.单笔限额：最低": "1. Batas per transaksi: Minimum",
	"元，最高": "Rp, Maksimum",
	"2.提现次数：一天最高提现": "2. Jumlah penarikan: Maksimum penarikan per hari",
	"次": "Kali",
	"3.到账时间：一般到账时间在5分钟左右，最快2分钟内到账": "3. Waktu pemrosesan: Umumnya waktu pemrosesan sekitar 5 menit, paling cepat dalam 2 menit.",
	"限额说明": "Keterangan Batas",
	"余额": "Saldo",
	"马上提现": "Segera Tarik",
	"请填写正确的金额": "Silakan masukkan jumlah yang benar",
	// "提现记录": "Rekaman Penarikan",
	// "数据为空": "Data Kosong",
	"说明": "Keterangan",
	"金额": "Jumlah",
	"提交时间": "Waktu Pengiriman",
	"审核时间": "Waktu Peninjauan",
	"没有更多了": "Tidak ada lagi",
	"播放": "Putar",
	"次播放": "Kali Putar",
	// "热门推荐": "Rekomendasi Populer",
	// "账号下线": "Akun Offline",
	"请充值后观看视频": "Silakan isi ulang untuk menonton video",
	"填写收款卡": "Isi Kartu Pembayaran",
	// "任务记录": "Rekaman Tugas",
	"选妃详情": "Detail Pemilihan Istri",
	// "我的": "Saya",
	"视频": "Video",
	"设置": "Pengaturan",
	// "修改真实姓名": "Ubah Nama Asli",
	"修改性别": "Ubah Jenis Kelamin",
	"登录密码管理": "Pengelolaan Kata Sandi Masuk",
	"详情页面": "Halaman Detail",
	"视频播放": "Pemutaran Video",
	"请填写完整": "Silakan lengkapi",
	"两次密码输入不一致": "Dua kali input kata sandi tidak cocok",
	"写真": "Foto",
	"现在预约": "Jadwalkan sekarang",
	"银行卡信息": "Daftar bank",
	"手机号码":"masuk nomor hp",
	"您的账号异常，已被冻结，请联系接待员处理":"Akun Anda bermasalah dan telah dibekukan, harap hubungi petugas untuk menyelesaikan masalah ini."
}