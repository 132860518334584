<template>
	<div class="convention-hall page">
		<van-nav-bar class="nav-bar" :title="this.$t('选妃')" />
		<div class="banner">
			<div class="swiper-slide">
				<div class="banner_img van-image">
					<img class="van-image__img" src="img/banner.gif">
				</div>
			</div>
		</div>
		<div class="convention-item">
			<van-tabs animated sticky line-width="100px" :swipeable="true">
				<van-tab title="VIP">
					<div class="horizontalItem" v-for="(item,key) in datalist" :key="key">
						<div class="imgBox">
							<van-image round width="120" height="120" radius="8" fit="cover"
								:src="item.img_url" />
							<span><i>{{$t('高端')}}</i></span>
						</div>
						<div style="width: calc(100% - 140px);">
							<div class="top">
								<div>
									<van-image width="13" src="img/home/vip.png" />
									<span>{{$t('认证')}}</span>
								</div>
								<div>
									<van-image width="13" src="img/home/vip.png" />
									<span>{{$t('视频认证')}}</span>
								</div>
								<div>{{item.xuanfei_name}}</div>
							</div>
							<div class="tags">
								<van-tag type="default">{{item.tag1}}</van-tag>
								<van-tag type="success">{{item.tag2}}</van-tag>
								<van-tag type="danger">{{item.tag3}}</van-tag>
								<van-tag type="warning">{{item.tag4}}</van-tag>
							</div>
							<div style="display: flex; align-items: center;">
								<span style="font-size: 14px; color: rgb(102, 102, 102);">{{$t('收费区间')}}：</span>
								<div tabindex="0" role="radiogroup" class="van-rate">
									<div role="radio" tabindex="0" aria-setsize="5" aria-posinset="1"
										class="van-rate__item" aria-checked="true">
										<van-icon name="star" color="#ffd21e" size="18" />
									</div>
									<div role="radio" tabindex="0" aria-setsize="5" aria-posinset="1"
										class="van-rate__item" aria-checked="true">
										<van-icon name="star" color="#ffd21e" size="18" />
									</div>
								</div>
							</div>
							<div style="color: rgb(153, 153, 153); font-size: 12px; margin: 5px 0px;"> {{$t('身高')}}: {{item.height}}
								{{$t('胸围')}}:{{item.xiongwei}}
								{{$t('服务城市：全国各地 航空运输：是')}} </div>
							<div class="yueta" @click = "addgo(item)">{{$t('我要约她')}}</div>
						</div>
					</div>
				
				
				</van-tab>

			</van-tabs>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				datalist:[]
			};
		},
		methods: {
			addgo(data) {
				if (!localStorage.getItem('token')) {
					this.$router.push({
						path: '/Login'
					});
				} else {
					this.$router.push({
						path: '/profile?id=' + data.id
					});
				}
			},
			getxuanfeilist() {
				this.$http({
					method: 'get',
					url: 'xuanfeilist',
				}).then(res => {
					this.datalist = res.data;
				});
			}

		},
		created() {
			this.getxuanfeilist()
		}
	};
</script>

<style lang="less" scoped>
	.page {
		position: absolute !important;
		top: 0;
		left: 0;
		right: 0;
		// background-color: #f2f2f5;
	}

	.nav-bar {
		// background: linear-gradient(90deg, #7e5678, #e6c3a1);
		background: linear-gradient(30deg, #7d76ef, #d63f8c);
		height: 100px;
	}

	.van-nav-bar {
		line-height: 50px;
	}

	::v-deep .van-nav-bar__title {
		max-width: 60%;
		margin: 0 auto;
		color: #ffffff;
		font-size: 35px;
	}

	::v-deep .van-nav-bar__content {
		height: 100px;
	}
	::v-deep .van-tab__pane-wrapper{
		padding-bottom: 80px;
		background-color: #f2f2f5;
	}

	.van-sidebar {
		width: 180px;
	}

	.convention-hall {
		display: flex;
		flex-direction: column;
		bottom: 20px;
		background: #f2f2f5;
	}

	::v-deep .van-tab {
		font-size: 30px;
		line-height: 100px;
		font-weight: bold;
		color: #c571cf;
	}

	::v-deep .van-tabs__line {
		background-color: #7e5678;
	}

	::v-deep .van-tabs--line .van-tabs__wrap {
		height: 100px;
	}

	::v-deep .van-tabs__wrap--scrollable .van-tab {
		padding: 0 23px;
	}

	.horizontalItem {
		display: flex;
		border-bottom: 1px solid #eee;
		padding: 2.667vw 0 4vw;
	}

	.horizontalItem .imgBox {
		position: relative;
		overflow: hidden;
		border-radius: 1.067vw;
		margin-right: 2.667vw;
	}

	.horizontalItem .imgBox>span {
		position: absolute;
		z-index: 9;
		background-color: #ebcaaf;
		color: #8d684b;
		transform: rotate(45deg);
		width: 20vw;
		height: 20vw;
		top: -11vw;
		right: -11vw;
	}

	.horizontalItem .imgBox>span>i {
		position: absolute;
		bottom: .667vw;
		left: 50%;
		transform: translateX(-50%);
		font-weight: 700;
		font-size: 4vw;
	}

	.horizontalItem .top {
		display: flex;
	}

	.horizontalItem .top>div:first-child,
	.horizontalItem .top>div:nth-child(2) {
		background: linear-gradient(180deg, #e7caaf, #fff7ed);
		color: #a4826b;
		font-weight: 600;
		font-size: 3vw;
		padding: .4vw;
		margin-right: 1.333vw;
	}

	.horizontalItem .tags {
		display: flex;
		margin: .667vw 0 .667vw;
		flex-wrap: wrap;
	}

	.horizontalItem .tags>.van-tag {
		padding: .667vw;
		font-size: 3.333vw;
		margin: 0 .667vw .667vw 0;
		border-radius: 1.067vw;
		line-height: 4vw;
	}

	.horizontalItem .yueta {
		background: linear-gradient(90deg, #df35ad, #4f1db5);
		color: #fff;
		border-radius: 1.067vw;
		padding: 1.333vw 0;
		text-align: center;
		font-size: 4vw;
	}
</style>