<template>
	<div class="container page">
		<van-nav-bar :title="this.$t('任务记录')" class="nav-bar">
			<template #left>
				<van-icon name="arrow-left" color="#fff" @click="back()" />
			</template>
		</van-nav-bar>
		<div class="main">
			<van-pull-refresh v-model="isLoading" @refresh="onRefresh" loosing-text="loading..." loading-text="loading...">
				<!-- <van-empty v-if="list.length === 0" description="No data" /> -->
				<van-empty v-if="list.length === 0" :description="this.$t('数据为空')" />
				<div v-else class="item_list" v-for="(v,key) in list" :key="key">
					<div class="lottery_info">
						<van-image class="cover" src="img/mine/record_item.png">
							<!-- <template v-slot:loading>
                <van-loading type="spinner"/>
              </template> -->
						</van-image>
						<span class="period-number">{{v.expect}}</span>
						<!-- <span class="period-number">{{v.name}}</span> -->
					</div>
					<div class="recent">
						<div class="kuaisan-ball left">
							<span class="res-des left">{{v.status === 0 ? 0 : v.opencode[0]}}</span>
							<span class="res-des left">{{v.status === 0 ? 0 : v.opencode[1]}}</span>
							<span class="res-des left">{{v.status === 0 ? 0 : v.opencode[2]}}</span>
							<span
								class="res-des middle">{{v.status === 0 ? 0 : v.opencode[0] + v.opencode[1] + v.opencode[2]}}</span>
							<span
								class="res-des right">{{v.status === 0 ? 0 : (v.opencode[0] + v.opencode[1] + v.opencode[2]) >= 11 && (v.opencode[0] + v.opencode[1] + v.opencode[2]) &lt;= 18 ? $t('鲜花') : $t('气球')}}</span>
							<span
								class="res-des right">{{v.status === 0 ? 0 : (v.opencode[0] + v.opencode[1] + v.opencode[2]) % 2 === 0 ? $t('棒棒糖') : $t('烟花')}}</span>
						</div>
					</div>
					
					<div class="topInfo">
						<span v-if="v.status === 1" style="color: #07c160">{{v.status_text}}</span>
						<span v-else>{{v.status_text}}</span>
						<span>{{$t("当前选号")}}:</span>
						<span v-for="(item,key1) in v.data" :key="key1">{{$t(item.type)}} </span>
					</div>
					<div class="topInfo">
						<span v-if="v.status === 1" style="color: #07c160">{{v.status_text}}</span>
						<span v-else>{{v.status_text}}</span>
						<span>{{$t("下注金额")}}：</span>
						<span>{{fixedToMoney(v.money)}}</span>
					</div>
					<!--   <div class="topInfo">
            <span v-if="v.is_win === 1" style="color: #07c160">{{v.win_text}}</span>
            <span v-else >{{v.win_text}}</span>
            <span>金额：{{v.profit}}</span>
          </div> -->
					<!-- <div class="topInfo">
            <span>任务类型：</span>
            <span>{{v.type}}</span>
          </div> -->
					<div class="time">
						<span>{{$t("下单时间")}}：{{v.create_time}}</span>
					</div>
					<div class="time">
						<span>{{$t("结算时间")}}：{{v.update_time}}</span>
					</div>
				</div>
			</van-pull-refresh>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				isLoading: false,
				list: [],
			};
		},
		methods: {
			back() {
				return window.history.back();
			},
			onRefresh() {
				setTimeout(() => {
					this.$toast(this.$t('刷新成功'));
					this.isLoading = false;
				}, 500);
			},
			getUserGameList() {
				this.$http({
					method: 'get',
					url: 'user_get_game_list'
				}).then(res => {
					if (res.code === 200) {
						console.log(res.data)
						this.list = res.data;
					} else if (res.code === 401) {
						this.$toast(res.msg);
					}
				})
			},
			fixedToMoney(value) {
			
				if (!value) return '--'
				var intPart = Number(value).toFixed(0) // 获取整数部分
				var intPartFormat = intPart.toString()
					.replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') // 将整数部分逢三一断
				var floatPart = '.00' // 预定义小数部分
				var value2Array = value.toString().split('.')
				// =2表示数据有小数位
				if (value2Array.length === 2) {
					floatPart = value2Array[1].toString() // 拿到小数部分
					if (floatPart.length === 1) {
						// 补0
						return intPartFormat + '.' + floatPart + '0'
					} else {
						return intPartFormat + '.' + floatPart
					}
				} else {
					return intPartFormat + floatPart
			
				}
			}

		},
		created() {
			if (!localStorage.getItem('token')) {
				this.$router.push({
					path: '/Login'
				})
			} else {
				this.getUserGameList();
			}
		}
	};
</script>

<style lang='less' scoped>
	@import "../../assets/css/base.css";

	::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
		color: #000000;
		font-size: 35px;
	}

	::v-deep .van-loading__text {
		color: #000000;
		font-size: 35px;
	}

	


	.container .main {
		position: relative;
		overflow: auto;
		background-color: #f2f2f5;
		height: 100%;
		padding: 0 10px;
	}

	.item_list {
		padding: 15px 15px;
		margin: 30px 10px;
		background: #fff;
		border-radius: 10px;
		line-height: 60px;
	}

	.item_list .topInfo span {
		flex: 1;
		font-size: 35px;
		font-weight: 700;
		color: #ff253f;
	}

	.item_list .time span {
		flex: 1;
		font-size: 25px;
		font-weight: 500;
		color: #000;
	}


	.item_list .desc span {
		font-size: 25px;
		font-weight: 700;
		color: #9b9b9b;
	}

	.item_list .cover {
		width: 60px;
		height: 60px;
		-o-object-fit: cover;
		object-fit: cover;
	}

	.item_list .period-number {
		margin-left: 50px;
		margin-right: 10px;
		height: 50px;
		line-height: 60px;
		font-size: 35px;
		font-weight: 700;
		color: #000;
	}

	.item_list .lottery_info {
		display: flex;
	}

	.recent {
		display: flex;
		align-items: center;
		height: 100px;
	}

	.kuaisan-ball .left {
		justify-content: flex-start;
	}

	.kuaisan-ball {
		flex: 1;
		display: flex;
		align-items: center;
	}

	.kuaisan-ball .res-img {
		width: 70px;
		height: 70px;
		margin-right: 30px;
	}

	.kuaisan-ball .res-des {
		font-weight: 700;
		text-align: center;
		color: #000;
	}

	/* .kuaisan-ball .res-des.middle {
		width: 30%;
		font-size: 35px;
	} */
	.kuaisan-ball .res-des.left {
		width: 5%;
		font-size: 4.667vw;
		color: #d75db4;
		font-weight: 700;
		margin-right: 2.4vw;
		font-size: 5vw;
	}
	.kuaisan-ball .res-des.middle {
		font-size: 4vw;
		background: linear-gradient(90deg, #f560cd, #4f2b8a);
		color: #fff;
		height: 7vw;
		line-height: 7vw;
		text-align: center;
		font-weight: 700;
		margin-right: 3.333vw;
		width: 7vw;
		border-radius: 50%;
	}

	.kuaisan-ball .res-des.right {
		font-size: 4vw;
		background: linear-gradient(90deg, #f560cd, #4f2b8a);
		color: #fff;
		height: 7vw;
		line-height: 7vw;
		text-align: center;
		font-weight: 700;
		margin-right: 3.333vw;
		padding: 0 2vw;
	}
</style>