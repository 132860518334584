<template>
  <div class="container page">
    <van-nav-bar :title="this.$t('提现中心')" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()"/>
      </template>
      <template #right>
        <span class="nav-right" @click="$router.push({path:'/WithdrawRecord'})">{{$t("提现记录")}}</span>
      </template>
    </van-nav-bar>
    <div class="main">
      <div class="withdrawMoney">
        <span>{{$t("提现金额 (元)")}}</span>
        <div class="money">
          <div class="moneyNumber">
            <!-- <span class="moneyType">¥</span> -->
            <van-field v-model="withdraw_money" type="number" />
          </div>
          <span class="all" @click="allMoeny()">{{$t("全部")}}</span>
        </div>
        <div class="information">
          <div class="description">
            <van-popover v-model="showPopover" trigger="click">
              <div class="popover-body" style="padding: 10px;">
                <p>{{$t("1.单笔限额：最低")}}{{this.withdrawRole.min}}{{$t("元，最高")}}{{this.withdrawRole.max}}{{$t("元")}}</p>
                <p>{{$t("2.提现次数：一天最高提现")}}{{this.withdrawRole.num}}{{$t("次")}}</p>
                <p>{{$t("3.到账时间：一般到账时间在5分钟左右，最快2分钟内到账")}}</p></div>
              <template #reference @click="withdrawInfo()">
                <van-icon name="info-o" />
                {{$t("限额说明")}}
              </template>
            </van-popover>
          </div>

          <div class="balance">
            <span>{{$t("余额")}}：</span>
            <span class="number">{{ this.common.fixedToMoney(this.userInfo.money) }}{{$t("元")}}</span>
          </div>
        </div>
      </div>
      <van-button class="withdraw_btn" type="default" @click="doWithdraw()">{{$t("马上提现")}}</van-button>
      <!-- <van-button class="withdraw_btn" type="default" @click="oshowKeyboard = true">马上提现</van-button>
		<van-password-input
		    :value="tpw"
		    :length="4"
		    :gutter="10"
		    :focused="tshowKeyboard"
		    @focus="tshowKeyboard = true;oshowKeyboard=false;"
		/>
	    <van-number-keyboard
	        v-model="opw"
	        :show="oshowKeyboard"
	        @input ="opw.length !== 3 ? oshowKeyboard = true  : tshowKeyboard=true;"
	        @blur="oshowKeyboard = false"
	    /> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showPopover: false,
      withdraw_money:"",
      userInfo:{},
      withdrawRole:{},
	  widrawPass:'',
	  oshowKeyboard: false
    };
  },
  methods: {
    back(){
      return window.history.back();
    },
    getUserInfo(){
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res=>{
        if(res.code === 200){
          this.userInfo = res.data;
          this.name = res.data.name;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    getUserWithdrawRole(){
      this.$http({
        method: 'get',
        url: 'user_get_withdraw_role'
      }).then(res=>{
        if(res.code === 200){
          this.withdrawRole = res.data;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    allMoeny(){
      this.withdraw_money = this.userInfo.money;
    },
    doWithdraw(){
      if(this.withdraw_money <= 0){
        this.$toast(this.$t('请填写正确的金额'));
        return false;
      }else {
        this.$http({
          method: 'post',
          data:{money:this.withdraw_money},
          url: 'user_set_withdraw'
        }).then(res=>{
          if(res.code === 200){
            location. reload()
            this.$toast(res.msg);
            this.getUserInfo();
            this.getUserWithdrawRole();
          }else if(res.code ===401){
            this.$toast(res.msg);
          }
        })
      }
    },
    withdrawInfo(){
      this.showPopover = true;
    }
  },
  created() {
    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }else {
      this.getUserInfo();
      this.getUserWithdrawRole();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";

::v-deep .van-password-input{
  width: 80%;
  height: 150px;
  margin: 0 auto;

}
::v-deep .van-password-input__security li {
  font-size: 30px;
  line-height: 30;
  background-color: #ebedf0;
}
::v-deep .van-password-input__security {
  height: 130px;
}
::v-deep .van-password-input .van-password-input__security .van-password-input__item {
  height: 100%;
  border: 0;
  text-align: center;
  border-radius: 30px;
}
.van-password-input__security i {
  width: 25px;
  height: 25px;
}
::v-deep .van-key {
  height: 100px;
  font-size: 55px;
  line-height: 20px;
  border-radius: 20px;
}
::v-deep .van-number-keyboard {
  z-index:100;
  width: 100%;
  padding-bottom: 30px;
  background-color: #f2f3f5;
}
::v-deep .van-key__collapse-icon {
   width: 50px;
   height: 50px;
 }
::v-deep .van-key__delete-icon {
  width: 50px;
  height: 50px;
}

.van-cell {
  font-size: 35px;
  line-height: 80px;
}
.container p{
  padding: 0 15px;
  margin-top: 15px;
  font-size: 30px;
  color: #dc2037;
}
.container .main{
  display: flex;
  flex-direction: column;
  background-color: #f2f2f5;
  height: calc(100% - 50px);
  position: relative;
}
.container .main .withdrawMoney{
  display: flex;
  flex-direction: column;
  color: #000;
  padding: 0 20px;
  white-space: nowrap;
  font-size: 35px;
  background-color: #fff;
}
.container .main .withdrawMoney span {
  padding: 10px 0;
}
.container .main .withdrawMoney .money{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #f2f2f5;
}
.container .main .withdrawMoney .money .moneyNumber{
  font-size: 50px;
  display: flex;
  flex-direction: row;
}
.container .main .withdrawMoney span {
  padding: 10px 0;
}
.container .main .withdrawMoney .money .all{
  color: #d10404;
}
.container .main .withdrawMoney .money .moneyNumber .van-cell {
  font-size: 50px;
  padding: 0!important;
}
.container .main .withdrawMoney .information{
  padding-bottom: 30px;
}
.container .main .withdrawMoney .information .description {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 0;
}
.container .main .withdrawMoney span{
  padding: 10px 0;
}
.container .main .withdrawMoney .information .balance .number{
  color: #d10404;
}
.withdraw_btn {
  margin: 20px 30px 0;
  height: 80px;
  line-height: 1.22667rem;
  border-radius: 50px;
  color: #fff;
  font-size: 30px;
  font-weight: bolder;
  border: none;
  background: linear-gradient(
      90deg,#e6c3a1,#7e5678);
}
</style>
