import Vue from 'vue';
import App from './App.vue';
import Vant from 'vant';
import 'vant/lib/index.css';
import router from './router';
import http from './http';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import common from './common/function'
import 'swiper/swiper-bundle.css'
import store from './store'
import 'video.js/dist/video-js.css'


// i18n
// import VueI18n from 'vue-i18n'

// Vue.use(VueI18n)

// const i18n = new VueI18n({
//   locale: 'en',
//   messages: {
//     'zh': require('./locale/en.js'),
//     'en': require('./locale/zh.js')
//   }
// })

import i18n from './locale/index.js'



Vue.prototype.$http = http
Vue.prototype.common = common
Vue.config.productionTip = false
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
Vue.use(Vant);
new Vue({
	i18n,
  store,
  router,
  render: h => h(App),
}).$mount('#app')
