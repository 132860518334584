import Vue from 'vue'
// 引入i18n插件
import VueI18n from 'vue-i18n'
// 引入语言包
import enLocale from './en.js'
import zhLocale from './zh.js'
import indLocale from './ind.js'
Vue.use(VueI18n)
const messages = {
  en: {
    ...enLocale,
  },
  zh: {
    ...zhLocale,
  },
  ind: {
    ...indLocale,
  }
}
// 配置i18n
const i18n = new VueI18n({
  locale: localStorage.getItem('lang') || 'ind', // 从缓存中获取当前的语言类型
  messages
})
export default i18n