<template>
	<div class="mine page">
		<div class="page-bg"></div>
		<div class="wrapper">
			<van-pull-refresh v-model="isLoading" @refresh="onRefresh" loosing-text="loading..." loading-text="loading...">
				<div class="header">
					<van-nav-bar class="nav-bar">
						<template #right>
							<van-icon name="home-o" color="#fff"/>
							 <!-- <van-icon name="setting-o" @click="showSetting()" color="#fff" /> -->
						</template>
					</van-nav-bar>
					<div class="user-wrapper" @click="doLogin()">
						<van-image round class="user_img" :src="this.userInfo.header_img">
							<template v-slot:loading>
								<van-loading type="spinner" />
							</template>
						</van-image>
						<div class="login-content">
							<p class="login-btn">{{this.userInfo.username}}</p>
							<!-- <p class="login-label">{{this.userInfo.ip}}</p> -->
						</div>
					</div>
				</div>
				<div class="content">
					<div class="finance">
						<div class="finance-item" @click="doPay()">
							<van-icon class="icon" style="" name="peer-pay" />
							<span class="text">{{$t("充值")}}</span>
						</div>
						<div class="line"></div>
						<div class="finance-item" @click="doWithdrawal()">
							<van-icon class="icon" name="idcard" />
							<span class="text">{{$t("提现")}}</span>
						</div>
					</div>
					<div v-if="this.userInfo.money" class="wallet">
						<div class="part-1 van-hairline--bottom">
							<p class="flex-1 font-28 ">{{$t("我的钱包")}}</p>
							<!-- <span class="font-28 font-gray">详情</span> -->
							<!-- <van-icon class="font-gray" style="font-size: 28px" name="arrow" /> -->
						</div>
						<div class="part-2">
							<p class="balance van-ellipsis">{{this.common.fixedToMoney(this.userInfo.money)}}</p>
							<span class="font-28">{{$t("余额(元)")}}</span>
							<div class="refresh-btn" @click="refresh()"><van-icon name="replay" /></div>
						</div>
					</div>


					<div :style="{ marginTop: menu_top +'px'}" class="menu">

						<div class="menu-item" @click="$router.push({path:'/Infomation'});">
							<van-image class="menu-item-icon" src="img/mine/nav1.png">
								<template v-slot:loading>
									<van-loading type="spinner" />
								</template>
							</van-image>
							<span class="menu-item-label">{{$t("个人中心")}}</span>
						</div>

						<div class="menu-item" @click="$router.push({path:'/SetLoginPassword'});">
							<van-image class="menu-item-icon" src="img/mine/nav7.png">
								<template v-slot:loading>
									<van-loading type="spinner" />
								</template>
							</van-image>
							<span class="menu-item-label">{{$t("登录密码")}}</span>
						</div>
						<div class="menu-item" @click="$router.push({path:'/GameRecord'});">
							<van-image class="menu-item-icon" src="img/mine/baobiao.png">
								<template v-slot:loading>
									<van-loading type="spinner" />
								</template>
							</van-image>
							<span class="menu-item-label">{{$t("任务记录")}}</span>
						</div>

						<div class="menu-item" @click="toNotice()">
							<van-image class="menu-item-icon" src="img/mine/4.png">
								<template v-slot:loading>
									<van-loading type="spinner" />
								</template>
							</van-image>
							<span class="menu-item-label">{{$t("系统公告")}}</span>
						</div>
						<div class="menu-item" @click="toService()">
							<van-image class="menu-item-icon" src="img/mine/5.png">
								<template v-slot:loading>
									<van-loading type="spinner" />
								</template>
							</van-image>
							<span class="menu-item-label">{{$t("在线客服")}}</span>
						</div>
						<div class="menu-item" @click="$router.push({path:'/Setbank'});">
							<van-image class="menu-item-icon" src="img/mine/nav1.png">
								<template v-slot:loading>
									<van-loading type="spinner" />
								</template>
							</van-image>
							<span class="menu-item-label">{{$t("银行卡信息")}}</span>
						</div>
					</div>

					<van-button class="sign-out" type="primary" @click="loginout()">{{$t("退出登录")}}</van-button>

				</div>
			</van-pull-refresh>
		</div>
	</div>

</template>

<script>
	export default {
		data() {
			return {
				userInfo: {},
				menu_top: 40,
				isLoading: false,
			};
		},
		methods: {
			refresh() {
				this.isLoading = true;
				setTimeout(() => {
					this.isLoading = false;
					if (localStorage.getItem('token')) {
						this.getUserInfo();
						this.$toast(this.$t('刷新成功'));
					} else {
						this.$router.push({
							path: '/Login'
						})
					}
				}, 500);
			},
			exit() {
				this.$toast(this.$t('请完成任务单后进入'));
			},
			loginout(){
			    localStorage.clear()
			    this.$router.push({path:'/home'});
			},
			showSetting() {
				if (localStorage.getItem('token')) {
					this.$router.push({
						path: '/Setting'
					})
				} else {
					this.$router.push({
						path: '/Login'
					})
				}
			},
			toNotice() {
				if (localStorage.getItem('token')) {
					this.$router.push({
						path: '/Notice'
					})
				} else {
					this.$router.push({
						path: '/Login'
					})
				}
			},
			onRefresh() {
				setTimeout(() => {
					this.isLoading = false;
					if (localStorage.getItem('token')) {
						this.getUserInfo();
						this.$toast(this.$t('刷新成功'));
					} else {
						this.$router.push({
							path: '/Login'
						})
					}
				}, 500);
			},
			doLogin() {
				if (localStorage.getItem('token')) {
					this.$router.push({
						path: '/Infomation'
					});
				} else {
					this.$router.push({
						path: '/Login'
					})
				}
			},
			doPay() {
				this.$toast(this.$t('请联系客服充值'));
				return false;
			},
			doWithdrawal() {
				this.$http({
					method: 'get',
					url: 'user_get_bank'
				}).then(res => {
					if (res.data.is_bank) {
						this.$router.push("withdraw");
					} else {
						this.$router.push("Setbank");
						this.$toast.fail(this.$t('请设置收款卡'));
					}
				})
			},
			toService() {
				if (this.$store.getters.getBaseInfo.iskefu == 1) {
					this.$router.push("ServiceOnline");
				} else {
					this.$toast.fail(this.$t('功能已禁用'));
				}
			},
			getUserInfo() {
				this.$http({
					method: 'get',
					url: 'user_info'
				}).then(res => {
					if (res.code === 200) {
						this.userInfo = res.data;
						this.menu_top = 15;
						if (this.userInfo.status == 0) {
							this.$toast(this.$t('您的账号异常，已被冻结，请联系接待员处理'));
							localStorage.clear()
							this.$router.push({
								path: '/Login'
							})
						}
					} else if (res.code === 401) {
						this.$toast(res.msg);
					}
				})
			},
		},
		created() {
			if (localStorage.getItem('token')) {
				this.getUserInfo();
			} else {
				this.userInfo.username = this.$t('登录/注册');
				this.userInfo.ip = this.$t('登录可享受更多服务');
				this.userInfo.header_img = "img/mine/avatar.png";
			}
		}
	};
</script>

<style scoped>
	.mine {
		position: relative;
		bottom: 14.667vw;
		background: #f2f2f5;
		background-size: contain;
	}

	.nav-bar {
		background: none !important;
	}

	.mine .page-bg {
		height: 66.667vw;
	}

	.page-bg {
		background: url(/img/mine/usertop.png) no-repeat top -16vw center;
		background-size: contain;
	}

	.mine .wrapper {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
	}

	.mine .header {
		padding-bottom: 13.333vw;
	}

	.mine .header .user-wrapper {
		display: flex;
		align-items: center;
		margin: 0 5.333vw 0 5.333vw;
	}

	.mine .user_img {
		height: 17.333vw;
		width: 17.333vw;
	}

	.mine .header .user-wrapper .login-content {
		flex: 1;
		margin-left: 4vw;
	}

	.mine .header .user-wrapper .login-content .login-btn {
		display: inline-block;
		font-size: 5.333vw;
		line-height: 0;
		color: #fff;
	}

	.mine .header .user-wrapper .login-content .login-label {
		margin-top: -1.733vw;
		font-size: 3.733vw;
		color: hsla(0, 0%, 100%, .6);
	}

	/* content */
	.mine .content {
		position: relative;
		padding: 10px 30px 30px;
		min-height: 500px;
		background-color: #f2f2f5;
	}

	::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
		color: #ffffff;
		font-size: 35px;
	}

	.mine .wrapper .content .finance {
		position: absolute;
		display: flex;
		align-items: center;
		top: -55px;
		left: 30px;
		right: 30px;
		height: 120px;
		background-color: #fff;
		border-radius: 15px;
		box-shadow: 0 1.5px 1px 0 #e4e4e7;
	}

	.mine .wrapper .content .finance .line {
		width: 3px;
		height: 40px;
		background-color: #ccc;
	}

	.mine .wrapper .content .finance .finance-item {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
	}

	.mine .wrapper .content .finance .finance-item .text {
		margin-left: 30px;
		font-size: 30px;
		color: #000;
		font-weight: 500;
	}

	.mine .wrapper .content .finance .finance-item .icon {
		font-size: 50px;
	}

	.mine .wrapper .content .menu {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		background-color: #fff;
		border-radius: 15px;
		box-shadow: 0 1.5px 1px 0 #e4e4e7;
	}

	.mine .wrapper .content .menu .menu-item {

		width: 33.33%;
		margin-bottom: 1.333vw;
	}

	.mine .wrapper .content .menu .menu-item .menu-item-label {
		font-size: 3vw;
		font-weight: 500;
		text-align: center;
		display: block;
		color: #432788;
	}

	.mine .wrapper .content .menu .menu-item .menu-item-icon {
		margin: 3vw;
		width: 8vw;
		height: 8vw;
		-o-object-fit: contain;
		object-fit: contain;
		display: block;
		margin: 3vw auto;
	}

	.mine .wrapper .content .wallet {
		margin-top: 11vw;
		padding: 0 4vw;
		border-radius: 3.333vw;
		background: #a06098;
		color: #fff;
	}

	.mine .wrapper .content .wallet .part-1 {
		display: flex;
		align-items: center;
		height: 100px;
	}

	.mine .wrapper .content .wallet .font-primary-color {
		color: #000;
	}

	.font-gray {
		color: #868686;
	}

	.mine .wrapper .content .wallet .part-2 {
		display: flex;
		align-items: center;
		height: 15vw;
	}

	.mine .wrapper .content .wallet .part-2 .balance {
		flex: 1;
		font-size: 60px;
		color: #fff;
		font-weight: 700;
	}

	.mine .wrapper .content .wallet .van-hairline--bottom::after {
		border-bottom-width: 3px;
	}

	.mine .wrapper .content .wallet .part-2 .refresh-btn {
		margin-left: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 50px;
		height: 50px;
		font-size: 30px;
		border-radius: 50%;
		color: #ffffff;
		background-color: #e6c3a1;
	}
	.sign-out {
	    margin: 11vw 0 0 0;
	    height: 12vw;
	    width: 100%;
	    line-height: 12vw;
	    border-radius: 3.333vw;
	    color: #fff;
	    font-size: 4vw;
	    font-weight: bolder;
	    border: none;
	    background: linear-gradient(90deg, #6529c9, #cc2996);
	}
</style>