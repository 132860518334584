export default {
	"语言": "语言",
	"首页": "首页",
	"电影": "电影",
	"投票": "投票",
	"我的": "我的",
	"请求失败": "请求失败",
	"选妃": "选妃",
	"高端": "高端",
	"认证": "认证",
	"视频认证": "视频认证",
	"收费区间": "收费区间",
	"身高": "身高",
	"胸围": "胸围",
	"服务城市：全国各地航空运输：是": "服务城市：全国各地航空运输：是",
	"我要约她": "我要约她",
	"住所": "住所",
	"个人简介": "个人简介",
	"请联系顾问或接待员": "请联系顾问或接待员",
	"全部": "全部",
	"刷新成功": "刷新成功",
	"尽享高端区": "尽享高端区",
	"享受极致奢华体验": "享受极致奢华体验",
	"空降": "空降",
	"查看更多": "查看更多",
	"热门推荐": "热门推荐",
	"获取中": "获取中",
	"登录": "登录",
	"请输入用户名": "请输入用户名",
	"请输入密码": "请输入密码",
	"忘记密码": "忘记密码",
	"没有账户？马上注册": "没有账户？马上注册",
	"注册": "注册",
	"请输入用户名(6-12个英文字母或数字)": "请输入用户名(6-12个英文字母或数字)",
	"请输入登录密码(6-12个英文字母或数字)": "请输入登录密码(6-12个英文字母或数字)",
	"我已经知晓并同意开户协议各项条约": "我已经知晓并同意开户协议各项条约",
	"请输入邀请码": "请输入邀请码",
	"请勾选下方开户协议": "请勾选下方开户协议",
	"投票结果": "投票结果",
	"提交": "提交",
	"当前选号": "当前选号",
	"每注金额": "每注金额",
	"请输入金额": "请输入金额",
	"元": "元",
	"总共": "总共",
	"注": "注",
	"合计": "合计",
	"任务单": "任务单",
	"清空订单": "清空订单",
	"确认提交": "确认提交",
	"期号": "期号",
	"中奖号码": "中奖号码",
	"鲜花": "鲜花",
	"气球": "气球",
	"棒棒糖": "棒棒糖",
	"烟花": "烟花",
	"未选择": "未选择",
	"金额错误": "金额错误",
	"请选号": "请选号",
	"请填写金额": "请填写金额",
	"余额不足，请联系客服充值": "余额不足，请联系客服充值",
	"未选中": "未选中",
	"请联系管理员领取该任务": "请联系管理员领取该任务",
	"开奖成功，期号：": "开奖成功，期号：",
	"请输入您的收款卡信息": "请输入您的收款卡信息",
	"银行卡号": "银行卡号",
	"请输入真实银行卡号": "请输入真实银行卡号",
	"银行名称": "银行名称",
	"请选择银行": "请选择银行",
	"尊敬的用户,为了保障您的资金安全,请您绑定您的真实姓名和设置取款密码,如果姓名与开户名不一致,将无法取款": "尊敬的用户,为了保障您的资金安全,请您绑定您的真实姓名和设置取款密码,如果姓名与开户名不一致,将无法取款",
	"确认绑卡": "确认绑卡",
	"请输入银行卡号": "请输入银行卡号",
	"任务记录": "任务记录",
	"下注金额": "下注金额",
	"下单时间": "下单时间",
	"结算时间": "结算时间",
	"充值": "充值",
	"提现": "提现",
	"我的钱包": "我的钱包",
	"余额(元)": "余额(元)",
	"个人中心": "个人中心",
	"登录密码": "登录密码",
	"系统公告": "系统公告",
	"在线客服": "在线客服",
	"请完成任务单后进入": "请完成任务单后进入",
	"请联系客服充值": "请联系客服充值",
	"请设置收款卡": "请设置收款卡",
	"功能已禁用": "功能已禁用",
	"账号下线": "账号下线",
	"登录/注册": "登录/注册",
	"登录可享受更多服务": "登录可享受更多服务",
	"基本信息": "基本信息",
	"头像": "头像",
	"选择头像": "选择头像",
	"确定": "确定",
	"真实姓名": "真实姓名",
	"未设置": "未设置",
	"性别": "性别",
	"男": "男",
	"女": "女",
	"未知": "未知",
	"绑定信息": "绑定信息",
	"已绑定": "已绑定",
	"无": "无",
	"收款卡信息": "收款卡信息",
	"添加收款卡": "添加收款卡",
	"提示:请绑定大型商业银行,如需修改,请您联系在线客服": "提示:请绑定大型商业银行,如需修改,请您联系在线客服",
	"请设置姓名后再绑定银行卡": "请设置姓名后再绑定银行卡",
	"修改登录密码": "修改登录密码",
	"旧密码": "旧密码",
	"请输入您的旧密码": "请输入您的旧密码",
	"新密码": "新密码",
	"请输入您的新密码": "请输入您的新密码",
	"请再次输入您的新密码": "请再次输入您的新密码",
	"保存": "保存",
	"修改真实姓名": "修改真实姓名",
	"姓名": "姓名",
	"请输入真实姓名": "请输入真实姓名",
	"为了您账户安全,真实姓名需要与绑定银行卡姓名一致": "为了您账户安全,真实姓名需要与绑定银行卡姓名一致",
	"请勿重复设置": "请勿重复设置",
	"性别修改": "性别修改",
	"提现中心": "提现中心",
	"提现记录": "提现记录",
	"提现金额 (元)": "提现金额(元)",
	"1.单笔限额：最低": "1.单笔限额：最低",
	"元，最高": "元，最高",
	"2.提现次数：一天最高提现": "2.提现次数：一天最高提现",
	"次": "次",
	"3.到账时间：一般到账时间在5分钟左右，最快2分钟内到账": "3.到账时间：一般到账时间在5分钟左右，最快2分钟内到账",
	"限额说明": "限额说明",
	"余额": "余额",
	"马上提现": "马上提现",
	"请填写正确的金额": "请填写正确的金额",
	"数据为空": "数据为空",
	"说明": "说明",
	"金额": "金额",
	"提交时间": "提交时间",
	"审核时间": "审核时间",
	"没有更多了": "没有更多了",
	"播放": "播放",
	"次播放": "次播放",
	"请充值后观看视频": "请充值后观看视频",
	"填写收款卡": "填写收款卡",
	"选妃详情": "选妃详情",
	"视频": "视频",
	"游戏": "游戏",
	"设置": "设置",
	"修改性别": "修改性别",
	"登录密码管理": "登录密码管理",
	"详情页面": "详情页面",
	"请填写完整": "请填写完整",
	"两次密码输入不一致": "两次密码输入不一致",
	"写真": "写真",
	"现在预约": "现在预约",
	"视频播放": "视频播放",
	"银行卡信息": "银行卡信息",
	"手机号码":"手机号码",
	"您的账号异常，已被冻结，请联系接待员处理":"您的账号异常，已被冻结，请联系接待员处理"
}
