<template>
	<div class="page">
		<van-nav-bar :title="xuanfeidata.xuanfei_name" class="nav-bar">
			<template #left>
				<van-icon name="arrow-left" color="#fff" @click="back()" />
			</template>
		</van-nav-bar>

		<div class="feiziInfo">
			<div class="topInfo">
				<div><span class="xuanfei_name">{{xuanfeidata.xuanfei_name}}</span></div>
				<div>{{$t("身高")}}: {{xuanfeidata.height}} {{$t("胸围")}}：{{xuanfeidata.xiongwei}}</div>
				<div class="tags">
					<van-tag type="default">{{xuanfeidata.tag1}}</van-tag>
					<van-tag type="success">{{xuanfeidata.tag2}}</van-tag>
					<van-tag type="danger">{{xuanfeidata.tag3}}</van-tag>
					<van-tag type="warning">{{xuanfeidata.tag4}}</van-tag>
				</div>
				<div><span style="color: rgb(107, 34, 179); font-size: 14px;">{{$t("收费区间")}}：</span>
					<div tabindex="0" role="radiogroup" class="van-rate">
						<div role="radio" tabindex="0" aria-setsize="5" aria-posinset="1" class="van-rate__item"
							aria-checked="true">
							<van-icon name="star" color="#ffd21e" size="18" />
						</div>
						<div role="radio" tabindex="0" aria-setsize="5" aria-posinset="1" class="van-rate__item"
							aria-checked="true">
							<van-icon name="star" color="#ffd21e" size="18" />
						</div>
						<div role="radio" tabindex="0" aria-setsize="5" aria-posinset="1" class="van-rate__item"
							aria-checked="true">
							<van-icon name="star" color="#ffd21e" size="18" />
						</div>
						<div role="radio" tabindex="0" aria-setsize="5" aria-posinset="1" class="van-rate__item"
							aria-checked="true">
							<van-icon name="star" color="#ffd21e" size="18" />
						</div>
						<div role="radio" tabindex="0" aria-setsize="5" aria-posinset="1" class="van-rate__item"
							aria-checked="true">
							<van-icon name="star" color="#ffd21e" size="18" />
						</div>
					</div>
				</div>
				<div style="color: rgb(107, 34, 179); font-size: 14px; margin: 4px 0px;">{{$t("住所")}}： </div>
				<div class="yuyueBox" @click="yuyue()">{{$t("现在预约")}}</div>
				<div class="dianzanimg">
					<div class="van-image" style="width: 60px; height: 60px;"><img src="img/xuanfei/zan.png"
							class="van-image__img" style="object-fit: cover;"></div>
					<p style="color: rgb(107, 34, 179); margin: 0px;">{{xuanfeidata.amount}}</p>
				</div>
			</div>
		</div>

		<div class="cenInfo">
			<van-tabs v-model="active" animated line-width="100px">
				<van-tab :title="this.$t('写真')">
					<div class="imgswiper">
						<van-image :class="{ img_active: imgActive==key }" v-for="(v,key) in xuanfeidata.img_url"
							:key="key" :name="key" fit="fill" height="100" width="100" style="margin-right: 10px;"
							:src="v" @click="changePic(key)" />
					</div>
					<van-image fit="fill" style="padding-top: 4vw;" :src="xuanfeidata.img_url[imgActive]" />
				</van-tab>
				<van-tab :title="this.$t('视频')"></van-tab>
			</van-tabs>
		</div>
		<div class="btmInfo">
			<div style="color: rgb(42, 20, 104); margin-bottom: 10px; font-weight: bold;"> {{$t("个人简介")}}</div>
			<div style="color: rgb(83, 69, 137);">
				<span>{{xuanfeidata.remark}}</span>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				xuanfeidata: [],
				active: 0,
				imgActive: 1,
			};
		},
		methods: {
			back() {
				this.$router.push({
					path: '/Choose'
				});
			},
			getxuanfeidata() {
				this.$http({
					method: 'get',
					url: 'xuanfeidata',
					// data: {
					// 	id: 4
					// }
					data: { id: this.$route.query.id }
				}).then(res => {
					this.xuanfeidata = res.data;
				});
			},
			changePic(key) {
				this.imgActive = key
			},
			yuyue() {
				this.$toast(this.$t('请联系顾问或接待员'));
			}
		},
		created() {
			this.getxuanfeidata();
		}
	};
</script>

<style>
	.btmInfo{
	    padding: 4vw 2.667vw;
	    background: #fff;
	    margin-bottom: 4vw;
	}
	
	.cenInfo {
		padding: 4vw 2.667vw;
		background: #fff;
		margin-bottom: 4vw;
	}

	.van-tab {
		font-size: 4vw;
		line-height: 4vw;
	}

	.van-tab--active {
		color: #323233;
		font-weight: 500;
	}

	.van-tabs__line {
		width: 100px;
		height: 0.6vw;
	}

	.van-tabs__nav--line {
		box-sizing: content-box;
		height: 100%;
		padding-bottom: 2vw;
	}

	.imgswiper {
		padding-top: 4vw;
	}

	.img_active {
		border: .933vw solid #e54878;
	}

	.feiziInfo .cenInfo .imgswiper {
		width: 100%;
		overflow-x: scroll;
		margin: 4vw 0;
		display: flex;
		flex-wrap: nowrap;
	}

	.container {
		display: inline-block;
	}

	.nav-bar {
		background: linear-gradient(30deg, #7d76ef, #d63f8c) !important;
		height: 100px;
	}

	.feiziInfo {
		background: #ebedf0;
	}

	.feiziInfo>div {
		padding: 4vw 2.667vw;
		background: #fff;
		margin-bottom: 4vw;
	}

	.feiziInfo .topInfo {
		position: relative;
	}

	.feiziInfo .topInfo>div:first-child {
		font-weight: 600;
	}

	.feiziInfo .topInfo>div:nth-child(2) {
		color: #6b22b3;
		font-size: 3vw;
		margin: 1.333vw 0;
	}

	.feiziInfo .topInfo>div:nth-child(3) {
		width: 50%;
	}

	.xuanfei_name {
		color: #2c1868;
		font-size: 5vw;
	}

	.feiziInfo .topInfo .tags>span {
		background: linear-gradient(50deg, #9b54ca, #e6557f);
		color: #fff;
		display: inline-block;
		border-radius: 1.333vw;
		margin-right: 3.333vw;
		font-size: 2.933vw;
		padding: 3vw;
		margin-bottom: 3.333vw;
	}

	.feiziInfo .topInfo .yuyueBox {
		position: absolute;
		right: 4vw;
		bottom: 2.667vw;
		color: #fff;
		padding: 2.667vw 4vw;
		background: linear-gradient(20deg, #e73266, #ee5380);
		border-radius: 7vw;
	}

	.feiziInfo .topInfo .dianzanimg {
		position: absolute;
		top: 4vw;
		right: 7vw;
		text-align: center;
	}
</style>