export default {
	"语言":"Language",
	"首页":"Home",
	"电影":"Movies",
	"我的": "My",
	"请求失败":"Request failed",
	"选妃":"Select a bride",
	"高端": "High-end",
	"认证":"Certification",
	"视频认证":"Video certification",
	"收费区间":"Fee range",
	"身高":"Height",
	"胸围": "cup size",
	"服务城市：全国各地 航空运输：是":"Service cities: Nationwide, Air transport: Yes",
	"我要约她":"I want to book her",
	"住所":"Residence",
	"个人简介":"Profile",
	"请联系顾问或接待员":"Please contact a consultant or receptionist",
	"投票":"Vote",
	"全部":"All",
	"刷新成功": "Refresh successful",
	"尽享高端区":"Enjoy the high-end area",
	"享受极致奢华体验":"Experience ultimate luxury",
	"空降":"Parachute in",
	"查看更多":"See more",
	"热门推荐":"Popular recommendations",
	"获取中":"Retrieving",
	"登录": "Login",
	"忘记密码":"Forgot password",
	"没有账户？马上注册":"No account? Register now",
	"请输入用户名":"Please enter username",
	"请输入密码":"Please enter password",
	"注册":"Register",
	"请输入用户名(6-12个英文字母或数字)":"Please enter username (6-12 letters or numbers)",
	"请输入登录密码(6-12个英文字母或数字)":"Please enter login password (6-12 letters or numbers)",
	"我已经知晓并同意开户协议各项条约":"I have read and agree to the account opening agreement",
	"请输入邀请码":"Please enter invitation code",
	"请勾选下方开户协议":"Please check the account opening agreement below",
	"投票结果":"Voting results",
	"提交":"Submit",
	"当前选号": "Current selection",
	"每注金额":"Amount per bet",
	"请输入金额": "Please enter amount",
	"元":"Yuan",
	"总共":"Total",
	"注":"Bets",
	"合计":"Total",
	"任务单": "Task list",
	"清空订单":"Clear order",
	"确认提交":"Confirm submission",
	"期号":"Issue number",
	"中奖号码":"Winning number",
	"鲜花":"Flowers",
	"气球":"Balloons",
	"棒棒糖":"Lollipops",
	"烟花": "Fireworks",
	"未选择":"Not selected",
	"金额错误":"Amount error",
	"请选号":"Please select numbers",
	"请填写金额":"Please enter amount",
	"余额不足，请联系客服充值":"Insufficient balance, please contact customer service to recharge",
	"未选中":"Not selected",
	"请联系管理员领取该任务": "Please contact the administrator to receive this task",
	"开奖成功，期号：":"Draw successful, issue number:",
	"请输入您的收款卡信息":"Please enter your payment card information",
	"银行卡号": "Bank card number",
	"请输入真实银行卡号": "Please enter a valid bank card number",
	"银行名称":"Bank name",
	"尊敬的用户,为了保障您的资金安全,请您绑定您的真实姓名和设置取款密码,如果姓名与开户名不一致,将无法取款":"Dear user, to ensure the safety of your funds, please bind your real name and set a withdrawal password. If the name does not match the account name, withdrawals will not be possible.",
	"确认绑卡":"Confirm binding card",
	"请输入银行卡号":"Please enter bank card number",
	"请选择银行":"Please select a bank",
	"数据为空":"Data is empty",
	"下注金额":"Bet amount",
	"下单时间":"Order time",
	"结算时间":"Settlement time",
	"充值":"Recharge",
	"提现": "Withdrawal",
	"我的钱包":"My wallet",
	"余额(元)":"Balance (Yuan)",
	"个人中心":"Personal center",
	"登录密码": "Login password",
	"任务记录":"Task Record",
	"系统公告": "System announcement",
	"在线客服":"Online customer service",
	"退出登录": "Logout",
	"请完成任务单后进入":"Please complete the task list before entering",
	"请联系客服充值":"Please contact customer service to recharge",
	"请设置收款卡":"Please set up a payment card",
	"功能已禁用":"Function has been disabled",
	"账号下线":"Account offline",
	"登录/注册": "Login/Register",
	"登录可享受更多服务":"Log in to enjoy more services",
	"基本信息":"Basic information",
	"头像":"Avatar",
	"选择头像": "Select Avatar",
	"确定": "Confirm",
	"真实姓名":"Real Name",
	"未设置":"Not set",
	"性别": "Gender",
	"男":"Male",
	"女": "Female",
	"未知": "Unknown",
	"绑定信息":"Binding Information",
	"已绑定":"Bound",
	"无":"None",
	// "系统公告": "System Announcement",
	"收款卡信息": "Payment Card Information",
	"添加收款卡":"Add Payment Card",
	"提示:请绑定大型商业银行,如需修改,请您联系在线客服":"Tip: Please bind to a large commercial bank. If you need to modify, please contact online customer service.",
	"请设置姓名后再绑定银行卡":"Please set your name before binding the bank card.",
	"修改登录密码":"Change Login Password",
	"旧密码":"Old Password",
	"请输入您的旧密码":"Please enter your old password",
	"新密码": "New Password",
	"请输入您的新密码":"Please enter your new password",
	"请再次输入您的新密码":"Please enter your new password",
	"保存":"Save",
	"修改真实姓名":"Change Real Name",
	"姓名":"Name",
	"请输入真实姓名":"Please enter your real name",
	"为了您账户安全,真实姓名需要与绑定银行卡姓名一致":"For your account security, the real name must match the name on the bound bank card.",
	"请勿重复设置":"Please do not set repeatedly.",
	"性别修改":"Gender Modification",
	"提现中心":"Withdrawal Center",
	"提现记录":"Withdrawal Record",
	"提现金额 (元)": "Withdrawal Amount (Yuan)",
	// "全部": "All",
	"1.单笔限额：最低": "1. Single transaction limit: Minimum",
	"元，最高":"Yuan, Maximum",
	"2.提现次数：一天最高提现": "2. Withdrawal times: Maximum withdrawals per day",
	"次":"Times",
	"3.到账时间：一般到账时间在5分钟左右，最快2分钟内到账":"3. Arrival time: Generally, the arrival time is about 5 minutes, with the fastest being within 2 minutes.",
	"限额说明":"Limit Explanation",
	"余额":"Balance",
	"马上提现": "Withdraw Now",
	"请填写正确的金额":"Please enter the correct amount",
	// "提现记录":"Withdrawal Record",
	// "数据为空":"Data is empty",
	"说明": "Description",
	"金额":"Amount",
	"提交时间":"Submission Time",
	"审核时间": "Review Time",
	"没有更多了": "No more",
	"播放":"Play",
	"次播放":"Times Played",
	// "热门推荐":"Popular Recommendations",
	// "账号下线":"Account Offline",
	"请充值后观看视频": "Please recharge to watch the video",
	"填写收款卡": "Fill in Payment Card",
	// "任务记录":"Task Record",
	"选妃详情":"Bride Selection Details",
	// "我的":"My",
	"视频":"Video",
	"游戏":"Games",
	// "在线客服":"Online Customer Service",
	"设置":"Settings",
	// "修改真实姓名":"Change Real Name",
	"修改性别":"Change Gender",
	"登录密码管理":"Login Password Management",
	"详情页面":"Details Page",
	"视频播放":"Video Playback",
	"请填写完整": " Please fill in completely",
	"两次密码输入不一致": "The two password entries do not match",
	"写真": "Photo",
	"现在预约": "Make an appointment now",
	"银行卡信息": "Bank Infromation",
	"手机号码":"Phone Number",
	"您的账号异常，已被冻结，请联系接待员处理":"Your account is abnormal and has been frozen. Please contact the receptionist for processing."
}
