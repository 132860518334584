<template>
  <div class="container page">
      <van-nav-bar :title="this.$t('语言')" class="nav-bar">
        <template #left>
          <van-icon name="arrow-left" color="#fff" @click="back()"/>
        </template>
      </van-nav-bar>
      <div class="items">
        <div class="item van-hairline--bottom" @click="selectI18('en')">
          <div class="left">English</div>
          <van-icon name="arrow" />
        </div>
		<div class="item van-hairline--bottom" @click="selectI18('ind')">
		  <div class="left">Indonesia</div>
		  <div class="right">
		    <van-icon name="arrow" />
		  </div>
		</div>
        <div class="item van-hairline--bottom" @click="selectI18('zh')">
          <div class="left">简体中文</div>
          <van-icon name="arrow" />
        </div>
        
      </div>
  </div>
</template>

<script>
import { Toast } from 'vant';
export default {
  data() {
    return {
      userInfo:{}
    };
  },
  methods: {
    back(){
      return window.history.back();
    },
    selectI18(value){
	  localStorage.setItem('lang', value);
	  Toast("Success")
	   location.reload() 
		setTimeout(() => {
			this.$router.push({path:'/login'});
		}, 1000)
	}
   
  },
  created() {
    
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
.container .items{
  background-color: #fff;
  font-size: 30px;
  color: #000;
  padding: 0 25px;
}
.container .items .item{
  padding: 30px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom-width: 10px;
}
.container .items .van-hairline--bottom::after {
  border-bottom-width: 3px;
}
.container .sign-out{
  margin: 500px 20px 0;
  height: 100px;
  line-height: 100px;
  border-radius: 50px;
  color: #fff;
  font-size: 40px;
  font-weight: bolder;
  border: none;
  background: linear-gradient(
      270deg,#e6c3a1,#7e5678);
}
.container  .item .desc{
  font-size: 30px;
  font-weight: 700;
  color: #979799;
}
.container .item .right{
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>
